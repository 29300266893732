<template>
  <div class="portfolio">
    <!-- <div class="about-me side">asdadss</div> -->
    <!-- @mousemove="mouseMoving" -->
    <div class="scene-wrap side">
      <a-scene
        renderer="antialias: true; colorManagement: true;"
        light="defaultLightsEnabled: false"
      >
        <!-- embedded -->
        <a-entity light="type: ambient; color: #BBB; intensity: 1.5"></a-entity>
        <a-entity
          light="type: directional;
                   castShadow: true;
                   intensity: 0.6;
                   shadowCameraVisible: false;"
          position="-0.5 1 1"
        ></a-entity>

        <!-- <a-box color="blue" rotation="0 70 0" position="0 1 0" material="side: double" scale="3 3 3"></a-box> -->
        <!-- <a-sphere @loaded="loaded"></a-sphere> -->

        <a-entity
          :camera="`fov: ${isMobile ? 100 : 60}`"
          :position="`${isMobile ? 0.5 : 1} 2 7`"
          rotation="-10 10 0"
          cursor="rayOrigin:mouse"
          raycaster="objects: .note"
        ></a-entity>
        <!-- <a-entity
          cursor="rayOrigin:mouse"
          raycaster="objects: .note"
          camera
          look-controls
          orbit-controls="target: 0 1.6 -0.5; enableKeys: false; enablePan: false; minDistance: 6; maxDistance: 7; initialPosition: 0 5 15; dampingFactor: 0.05; screenSpacePanning: true; minPolarAngle: 0; maxPolarAngle: 3; maxAzimuthAngle: 5; minAzimuthAngle: 5"
        ></a-entity> -->

        <!-- orbit-controls="
              enableDamping: true;
              dampingFactor: 0.125;
              rotateSpeed:0.25;
              minDistance:3;
              maxDistance:100;" -->
        <!-- <a-box color=purple shadow="receive: true" scale="1.8 0.13 1.9" position="0 0.1 0"></a-box>
        <a-box color=purple shadow="receive: true" scale="1.5 0.13 1.9" position="-1 0.8 0" rotation="0 0 90"></a-box> -->
        <a-entity class="objects" ref="objects">
          <Piano :isMobile="isMobile"/>
          <Clock />

          <a-gltf-model
            shadow="receive: true"
            class="room"
            src="objects/room.glb"
            ref="room"
            animation-mixer
            cube-env-map="path: cubemap/;
                        extension: png;
                        reflectivity: 0.5;"
          >
            <a-entity
              :light="`type: spot; angle: 41.93; color: ${lamp}; penumbra: 0.2; intensity: 0.3;`"
              rotation="-53.08 -58.5 0"
              position="-1.84769 2.01716 2.2199"
            ></a-entity>
          </a-gltf-model>
          <a-ocean
            position="-2.63634 -0.0.5 1.94109"
            material="side: double; opacity: 0.3"
            color="#92E2E2"
            width="25"
            depth="25"
            scale="0.055 0.055 0.055"
            density="10"
            speed="2"
            ocean=""
          ></a-ocean>
        </a-entity>
      </a-scene>
    </div>
  </div>
</template>

<script>
// import "../../scripts/audiosynth.js";
// this.piano = Synth.createInstrument("piano");
// import { Instrument } from "piano-chart";
import Clock from "@/components/Portfolio/Clock";
import Piano from "@/components/Portfolio/Piano";
import { log } from "tone/build/esm/core/util/Debug";

export default {
  name: "Portfolio",
  components: {
    Clock,
    Piano,
  },

  mounted() {
    this.isMobile = AFRAME.utils.device.isMobile();
  },
  data() {
    return {
      isMobile: false,
      on: false,
      editOpen: true,
      walls: "#a36c7e",
      floor: "#534F82",
      lamp: "#FFFFFF",
      notes: ["A", "Ab", "B", "Bb", "C", "D", "Db", "E", "Eb", "F", "G", "Gb"],
    };
  },
  methods: {
    loaded: function (e) {
      console.log(e.target.object3D);
    },
    // mouseMoving: function (e) {
    //   let currentThresh = 0;
    //   let threshold = 10;
    //   var objects = this.$refs.objects;
    //   currentThresh++;
    //   console.log(currentThresh);
    //   console.log(threshold);
    //   if (currentThresh === threshold) {
    //     let y = e.movementY;
    //     let x = e.movementX;
    //     let yDest = x * 100;
    //     let xDest = y * 100;
    //     let animation = `property: rotation; to: ${xDest} ${yDest} 0; dur: 1000`;

    //     // yDest = x * 10;
    //     // xDest = y * 10;
    //     console.log(objects);
    //     objects.setAttribute("animation", animation);
    //     console.log(e);
    //     currentThresh = 0;
    //   }

    //   // y > 0 ? xDest = 20;
    // },
    playNote: function () {
      const now = Tone.now();
      this.synth.triggerAttackRelease("C4", "8n"); // sounds[index].load();
    },
  },

  watch: {
    walls: function (e) {
      console.log(this.$refs.room.object3D.children[0].children[0]);
      this.$refs.room.object3D.children[0].children[0].children[0].material.color =
        new THREE.Color(e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.portfolio {
  display: flex;
  width: 100%;
  height: 100%;
  .side {
    flex: 1;
  }
}
.about-me {
  background: white;
}
a-scene {
  width: 100%;
  height: 100%;
}
.edit {
  position: absolute;
  bottom: 16px;
  left: 16px;
  // background: white;
  z-index: 5;
  transition: 0.4s;

  // max-width: 220px;
  // max-height: 300px;
  text-align: left;
  border-radius: 5px;

  padding: 20px;
  display: flex;
  overflow-x: auto;
  width: 100%;
  max-width: 99%;
  .item {
    background: white;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  label {
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 1.5rem;
    font-weight: 500;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
}
</style>